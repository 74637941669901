html{
  box-sizing: border-box;
  scroll-behavior: smooth;
  overflow-x: hidden;
  width: 100%
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  font-size: 1.1rem;
}


@font-face {
  font-family: 'Expansiva Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Expansiva Regular'), url('/public/fonts/Expansiva.woff') format('woff');
}

@font-face {
  font-family: 'Expansiva Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Expansiva Bold'), url('/public/fonts/Expansiva bold.woff') format('woff');
}

/*@font-face {*/
/*  font-family: "Expansiva";*/
/*  src: url("https://db.onlinewebfonts.com/t/ded4f5f00a0fd0905edb268df0d6351c.eot");*/
/*  src: url("https://db.onlinewebfonts.com/t/ded4f5f00a0fd0905edb268df0d6351c.eot?#iefix")format("embedded-opentype"),*/
/*  url("https://db.onlinewebfonts.com/t/ded4f5f00a0fd0905edb268df0d6351c.woff2")format("woff2"),*/
/*  url("https://db.onlinewebfonts.com/t/ded4f5f00a0fd0905edb268df0d6351c.woff")format("woff"),*/
/*  url("https://db.onlinewebfonts.com/t/ded4f5f00a0fd0905edb268df0d6351c.ttf")format("truetype"),*/
/*  url("https://db.onlinewebfonts.com/t/ded4f5f00a0fd0905edb268df0d6351c.svg#Expansiva")format("svg");*/
/*}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

.expansiva {
  font-family: "Expansiva Regular";
}
.expansiva-bold {
  font-family: "Expansiva Bold";
}
.white{
  color: white;
}
.black{
  color: black;
}

.center{
  text-align: center;
}
.bw1{
    border : 1px solid white;
}
.mt1{
    margin-top: 1rem;
}
button, .button{
  background-color: #000;
  width: fit-content;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 1.2rem;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 0.8rem;
  transition: 0.2s all;
  border: 2px solid #000;

}
.languageSelectorButton:hover{
  opacity: 0.6;
}
.languageSelectorButton:disabled{
  opacity: 0.3;
}
button:hover, .button:hover{
  background-color: #333;
}

button.skew:hover::after, .button.skew:hover::after, button.skew-reverse:hover::after, .button.skew-reverse:hover::after{
  background-color: #333;
}

button.skew, .button.skew{
  border-radius: 1.5rem;
  border-top-right-radius: 6em;
  position: relative;
  will-change: background-color;
  font-family: "Expansiva Regular";
}
button.skew, .button.skew-reverse{
  position: relative;
  will-change: background-color;
  font-family: "Expansiva Regular";
}
button.skew.with-icon{
  padding: 15px 50px 15px 26px;

}
button.skew::after, .button.skew::after{
  transition: 0.2s all;
  will-change: background-color;
  content: "";
  position: absolute;
  display: block;
  background: #000;
  border: 2px solid #000;
  width: 3em;
  height: 100%;
  top: -2px;
  -webkit-transform-origin: bottom left;
  -moz-transform-origin: bottom left;
  transform-origin: bottom left;
  right: -2.8rem;
  border-left: none;
  -webkit-transform: skewX(-20deg);
  -moz-transform: skewX(-20deg);
  transform: skewX(30deg);
  border-bottom-right-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
}

button.skew-reverse::after, .button.skew-reverse::after{
  transition: 0.2s all;
  will-change: background-color;
  content: "";
  position: absolute;
  display: block;
  background: #000;
  border: 2px solid #000;
  width: 3em;
  height: 100%;
  top: -2px;
  -webkit-transform-origin: bottom left;
  -moz-transform-origin: bottom left;
  transform-origin: bottom left;
  left: -0.5rem;
  border-left: none;
  -webkit-transform: skewX(-20deg);
  -moz-transform: skewX(-20deg);
  transform: skewX(30deg);
  border-top-left-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
}

.fr{
  display: flex;
    flex-direction: row;
}
.fw-b{
  font-weight: bold;
}
.cr{
  color: red;
}
.clr{
  color: #ff8787;
}
.fc{
  display: flex;
  flex-direction: column;
}
.f-c{
  align-items: center;
  justify-content: center;
}
.g1{
  gap: 1rem;
}
.g2{
  gap: 2rem;
}
.jc-c{
  justify-content: center;
}
.jc-sb{
  justify-content: space-between;
}
.mt-50{
    margin-top: 3rem;

}
.fw-w{
  flex-wrap: wrap;
}
.fs1{
  font-size: 1rem;
}
.fs1-5{
  font-size: 1.5rem;
}
.fs2{
  font-size: 2rem;
}
.fs3{
  font-size: 3rem;
}
.fs3-5{
  font-size: 3.5rem;
}
/*article{*/
/*  margin: 0 2rem;*/
/*  max-width: 100%;*/
/*}*/
article{
  padding: 0 2rem;
  margin:auto;
  max-width: 1400px;
}

menu, ul, li{
  padding: 0;
  margin: 0;
}

li::marker{
  font-weight: bold;
}

.w100{
  width: 100%;
}
.h100{
  height: 100%;
}

.bold{
    font-weight: bold;
}

.menu.open{
  opacity: 1;
  pointer-events: all;
}
.menu.close{
  opacity: 0;
  pointer-events: none;
}

.o-5{
    opacity: 0.5;
}
.o-8{
    opacity: 0.8;
}

.bold{
    font-weight: bold;
}
.mt-a{
    margin-top: auto;
}
.bg-t{
  background-color: transparent;
}

.b0{
  border: 0;
}





.modal_bg {
  background-color: rgb(50 62 95 / 43%);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.modal{
  margin: 2rem;
  /*width: 100%;*/
  /*max-width: 300px;*/
  max-height: 90vh;
  color: #333;
  overflow: hidden;
  background: white;
  border-radius: 15px;
  border: 1.5px solid #777f8e;
  padding: 0.7rem 0.7rem 0.7rem 0.7rem;
  display: flex;
  flex-direction: column;
}
.modal_content {
  background-color: #e4f3ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 2rem 2rem 2rem;
  gap: 1rem;
  border-radius: 0.5rem;
  position: relative;
  overflow-y: scroll;
}

.modal .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.1rem 0.7rem 0.3rem 0.75rem;

}
.modal .close:hover{
  background-color: #333;
  color: white;
  border-radius: 0.5rem;
}

form label{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
input[type=text], input[type=email], textarea, select{
  width: 100%;
  max-width: 500px;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 2px solid black;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  box-sizing: border-box;
  -webkit-box-sizing:border-box;
  -moz-box-sizing: border-box;
  font-family: Arial;
}
textarea{
  resize: vertical;
  min-height: 100px;
  max-height: 200px;
}


.slick-slide{
  position: relative;
  aspect-ratio: 1/1;
  overflow: hidden;
}

.slick-slide {
  box-sizing: border-box;
}
.slick-slide img{
  z-index: -1;
}
.slick-slide a:before{
  position: absolute;
  width: 100%;
    height: 100%;
    content: '';
  background-color: rgba(0,0,0,0.4);
  opacity: 0.8;
  z-index: 0;
}
.slick-slide > div > span > a >div > h2{
  position: relative;
  z-index: 1;
}

.black_overlay:before{
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(0,0,0,0.4);
    opacity: 0.8;
    z-index: 0;
}
.black_overlay h2{
    z-index: 1;
}

.slick-slide > div {
  /*margin: 0 10px;*/
  position: relative;
  width: 100%;
  height: 100%;
}
.slick-list {
  margin: 0 -10px;
}


section{
  background-image: url("../public/bg_waves.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


/* css media query to apply style to mobile screens */

#home_hero_section{
  min-height: 80vh;
  justify-content: center
}
#home_hero_section h1{
  margin-bottom: 0;
}
#home_hero_section p{
  margin-top: 0;
  margin-bottom: 1rem;
}
.show_mobile{
  display: none!important;
}
.show_mobile button{
  background-color: transparent;
  border: 0;
}
#topbar_logo, #menu_logo{
  max-width: 100px!important;
}
@media (max-width: 740px) {
  .fs1_mobile{
    font-size: 1rem;
  }
  .column_on_mobile{
    flex-direction: column;
  }
  .center_on_mobile{
    text-align: center!important;
  }
  .noMarginleftOnMobile{
    margin-left: 0!important;
  }

  .slick-slider .button.skew-reverse , .slick-slider .button.skew{
    left: 50%!important;
    transform: translate(-50%, 0%);
    font-size: 1rem;
  }
  #home_hero_section{
    min-height: auto;
  }
  #home_hero_section h1{
    font-size: 2.5rem;
    margin: 0.67em;
  }
  #home_hero_section a{
    font-size: 1rem;
  }
  .show_mobile{
    display: flex!important;
  }


  .hide_mobile{
    display: none!important;
  }
  .carsContainer{
    display: none!important;
  }


  #home_header{
    background-image: url("../public/home_header.png")!important;
  }

  #home_header_overlay{
    /*opacity: 0!important;*/
  }




  section article div div.fc, section article p{
    margin-left: 0!important;
  }

  .registerWidget img{
    width: 6rem!important;
    height: 6rem!important;
  }
  .registerWidget div{
    font-size: 1rem!important;
    padding: 0.6rem!important;
    border-radius: 0.5rem!important;
  }
}
.ai-c{
    align-items: center;
}
.column_on_mobile{
gap: 0 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
}
.column_on_mobile h1{
  text-wrap: nowrap;
  margin: 0;
}
.column_on_mobile h1.tw{
  text-wrap: wrap;
}
@media (max-width: 977px) {

  #tracks_container{
    flex-direction: column!important;
    align-items: center
  }
}

@media (max-width: 1000px) {

  .remove_padding_bottom_on_mobile{
    padding-bottom: 0!important;
  }
  #event_article{
    flex-direction: column!important;
  }
  #polygon_image{
    display: none!important;
  }
  #polygon_image_alt{
    display: inline!important;
  }
}

#carSeparator{
  min-width: 40vw;
  animation: carSeparator 2s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes carSeparator {
  0% {
    min-width: 100vw;
  }
  50% {
    min-width: 100vw;
  }
  100% {
    min-width: 40vw;

  }
}

.st4{
  position: relative;
}
.tw-w{
  white-space: wrap!important;
  text-wrap: wrap!important;
}
.event-modale{
  position: relative;
}
.event-modale:after{
  content: 'Chargement ...';
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    font-weight: bold;
  z-index: 999999999;
  animation: hideModale 0.5s 2s forwards;
}

@keyframes hideModale {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.registerWidget{
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 999999999;
    padding: 1rem;
    color: white;
    transition: 0.2s all;
  display: flex;
    flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
  pointer-events: none;
}

.registerWidget img{
    width: 8rem;
    height: 8rem;
  pointer-events: auto;
  cursor: pointer;
  transition: all 0.2s;
}

.registerWidget div{
  background-color: #3075f7;
    padding: 1rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  position: relative;
  animation: fadeIn 0.5s forwards, jump 3s infinite;
  pointer-events: auto;
  cursor: pointer;
  transition: all 0.2s;
}

.registerWidget div:hover, .registerWidget img:hover{
    opacity: 0.8!important;

}
.registerWidget div:before {
  content: "";
  display: block;
  width: 0;
  border-width: 15px 15px 0px 15px;
  border-color: #3075f7 transparent;
  border-style: solid;
  position: absolute;
  bottom: -15px;
  right: 20%;
  transform: translateX(50%);
}

.registerWidget div h2, .registerWidget div p{
    margin: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes jump {
  15% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  35% {
    transform: translateY(0);
  }
}

.pdfViewer{
  width: 100%;
  max-width: 700px;
overflow-y: scroll;
  background-color: rgba(0,0,0,0.8);
  gap: 1rem;
  padding: 1rem;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pdfViewer img{
    width: 100%!important;
    height: auto;
    border: 1px solid white;
}